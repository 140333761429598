import React, { useMemo } from "react"

import Drawer from "@mui/material/Drawer"
import Stack from "@mui/system/Stack"

import { useAppDrawer } from "@hooks/useAppDrawer"
import useSession from "@hooks/useSession"

import OrgSwitcher from "@components/OrgSwitcher"
import { isLightMode } from "@helpers/isLightMode"

import { sidebarSubMenus, featureNavs } from "@constants/features"

import { SidebarMenu } from "../SidebarMenu"
import { filterUnauthorizedSubMenus } from "../SidebarMenu/filterUnauthorizedSubMenus"
import { prependFavoriteFeatures } from "../SidebarMenu/prependFavoriteFeatures"

export const appDrawerWidth = 220
export const AppDrawer: React.FC = () => {
  const { open } = useAppDrawer()

  const { orgMember, canViewReporting, canViewWorkspacePeople } = useSession()

  const availableSubMenus = useMemo(() => {
    const authorizedSubMenus = filterUnauthorizedSubMenus({
      subMenus: sidebarSubMenus,
      canViewReporting,
      canViewWorkspacePeople,
    })

    const favoriteFeatures = orgMember?.settings?.starredFeatures?.nodes ?? []

    return prependFavoriteFeatures({
      authorizedSubMenus,
      favoriteFeatures,
      featureNavs,
    })
  }, [
    canViewReporting,
    canViewWorkspacePeople,
    orgMember?.settings?.starredFeatures?.nodes,
  ])

  return (
    <Stack direction="column">
      <Drawer
        variant="persistent"
        open={open}
        sx={{ zIndex: 1 }}
        anchor="left"
        transitionDuration={50}
        PaperProps={{
          sx: (theme) => ({
            whiteSpace: "nowrap",
            zIndex: 1,
            top: 30,
            border: 0,
            width: appDrawerWidth,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            overflowX: "hidden",
            backgroundColor: isLightMode(theme)
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          }),
        }}
      >
        <SidebarMenu
          subMenus={availableSubMenus}
          orgSwitcher={<OrgSwitcher />}
        />
      </Drawer>
    </Stack>
  )
}
