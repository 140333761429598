export const COMPANY_URL = "digit-software.com"
export const CONTACT_EMAIL = "info@digit-software.com"
export const PRODUCTION_SITE_URL = "https://digit-software.com"

export const DEV_ENVS = {
  DEV: "development",
  STAGING: "staging",
  PROD: "production",
  TEST: "test",
}

// An alert will be triggered on the backend if a user's data size exceeds 25MB.
export const DEFAULT_PAGES_SIZE = 50
export const PAGINATED_PAGES_SIZE = 100

export const DEFAULT_CURRENCY = "USD"
export const PLACEHOLDER = "placeholder"

export const RCV = "RSV-"

export const SCAN_CODE_PREFIX = {
  RCV: "rcv", // Received inventory
  WL: "wl", // Warehouse Location
  MI: "mi", // Manual inventory
  JOB: "job", // Production inventory
}

export const ERROR_CODES = {
  NOT_FOUND: "The requested resource was not found.",
}
