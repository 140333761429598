import React, { Suspense, useMemo } from "react"

import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"

import ErrorBoundary from "@components/ErrorBoundary"
import Modal from "@components/Modal"
import { isLightMode } from "@helpers/isLightMode"
import { SidebarMenu } from "@views/Layout/SidebarMenu"

import { getSettingsSidebarSubMenus } from "@constants/features"

import { SettingsPage } from "./SettingsPage"
import { useSettingsPageTabRouter } from "./useSettingsPageTabRouter"

const SettingsModal: React.FC<{
  open: boolean
  toggleOpen: () => void
}> = ({ open, toggleOpen }) => {
  const [currentPageName, setPage] = useSettingsPageTabRouter()

  const sidebarSubMenus = useMemo(() => {
    return getSettingsSidebarSubMenus({ setPage, currentPageName })
  }, [setPage, currentPageName])

  return (
    <ErrorBoundary>
      <Modal
        open={open}
        hideBottomActions
        setOpen={toggleOpen}
        sx={{
          width: "100%",
          maxWidth: "90vw",
          height: "90vh",
          p: 0,
        }}
      >
        <Stack direction="row" sx={{ gap: 2, m: 0 }}>
          <Paper
            sx={(theme) => ({
              zIndex: 1,
              [theme.breakpoints.down("md")]: {
                whiteSpace: "unset",
              },
              whiteSpace: "nowrap",
              top: 30,
              border: 0,
              borderRadius: 0,
              pr: 6,
              mr: 2,
              overflowX: "hidden",
              backgroundColor: isLightMode(theme)
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            })}
          >
            <SidebarMenu
              subMenus={sidebarSubMenus}
              stackSx={{ pt: 2, minWidth: "min-content", mr: 3 }}
            />
          </Paper>

          <Container
            disableGutters
            sx={{
              overflow: "auto",
              p: 4,
              pr: 8,
              height: "90vh",
              "@media ((min-width: 1200px))": {
                maxWidth: "1800px",
              },
            }}
          >
            <Suspense
              fallback={
                <Stack
                  direction="column"
                  gap={2}
                  data-testid="loading-skeleton"
                >
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={400} />
                </Stack>
              }
            >
              <SettingsPage currentPageName={currentPageName} />
            </Suspense>
          </Container>
        </Stack>
      </Modal>
    </ErrorBoundary>
  )
}

export default SettingsModal
