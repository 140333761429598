import { HeroIconProps } from "@components/HeroIcon"
import { isLightMode } from "@helpers/isLightMode"

export const subMenuItemIconColor: HeroIconProps["color"] = (theme) =>
  isLightMode(theme) ? theme.palette.grey[600] : theme.palette.grey[400]

export const subMenuItemHoverArrowIconColor: HeroIconProps["color"] = (
  theme,
) => (isLightMode(theme) ? theme.palette.grey[400] : theme.palette.grey[700])

export const subMenuItemIconSize = 15
