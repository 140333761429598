import { isDarkMode } from "@helpers/isDarkMode"
import { isLightMode } from "@helpers/isLightMode"

import type { SystemStyleObject, Theme } from "@mui/system"

interface ThemeWithModeOnly {
  palette: { mode: Theme["palette"]["mode"] }
}

export interface LightVsDarkStylesParams {
  defaultStyles?: SystemStyleObject<Theme>
  lightStyles?: SystemStyleObject<Theme>
  darkStyles?: SystemStyleObject<Theme>
}

/**
 * Helper function to apply light or dark styles based on the current theme
 *
 * To avoid writing the same theme.palette.mode === "dark" check every time
 *
 * @example <Box sx={lightVsDarkStyles({ lightStyles, darkStyles })} /> For light and dark mode styles
 * @example <Box sx={lightVsDarkStyles({ lightStyles })} /> For light mode only styles
 * @example <Box sx={lightVsDarkStyles({ darkStyles })} /> For dark mode only styles
 */
export const lightVsDarkStyles =
  (params: LightVsDarkStylesParams) =>
  (theme: ThemeWithModeOnly): SystemStyleObject<Theme> => {
    if (isDarkMode(theme) && "darkStyles" in params) {
      return { ...params.defaultStyles, ...(params.darkStyles ?? {}) }
    }

    if (isLightMode(theme) && "lightStyles" in params) {
      return { ...params.defaultStyles, ...(params.lightStyles ?? {}) }
    }

    return params.defaultStyles ?? {}
  }
