import { SubMenu } from "../SubMenu"

export const filterUnauthorizedSubMenus = ({
  subMenus,
  canViewReporting,
  canViewWorkspacePeople,
}: {
  subMenus: SubMenu[]
  canViewReporting: boolean
  canViewWorkspacePeople: boolean
}): SubMenu[] => {
  return subMenus.filter(({ id }) => {
    if (id === "reporting" && !canViewReporting) {
      return false
    }

    if (id === "people" && !canViewWorkspacePeople) {
      return false
    }

    return true
  })
}
