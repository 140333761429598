import React from "react"

import ListItemButton from "@mui/material/ListItemButton"
import Typography from "@mui/material/Typography"
import Stack from "@mui/system/Stack"
import { useTranslation } from "react-i18next"

import { HeroIcon } from "@components/HeroIcon"
import { lightVsDarkStyles } from "@helpers/lightVsDarkStyles"

import { activeLinkStyles } from "../activeLinkStyles"
import { subMenuItemIconColor, subMenuItemIconSize } from "../subMenuItemUtils"
import { SubMenuItemBase } from "../types"

export interface SubMenuItemWithClickHandler extends SubMenuItemBase {
  onClick: () => void
  isActive: boolean
}

export const SubMenuItemWithClickHandler: React.FC<
  SubMenuItemWithClickHandler
> = ({ icon, translatedTextKey, isActive, onClick }) => {
  const { t } = useTranslation()

  return (
    <ListItemButton
      sx={lightVsDarkStyles({
        defaultStyles: {
          pl: 1,
          py: 0.5,
          mr: 1,
          borderRadius: 0.5,
        },

        ...activeLinkStyles({ isActive }),
      })}
      onClick={onClick}
      component="button"
    >
      <Stack direction="row" gap={1} alignItems="center">
        {icon && (
          <div data-testid="submenu-item-icon">
            <HeroIcon color={subMenuItemIconColor} size={subMenuItemIconSize}>
              {icon}
            </HeroIcon>
          </div>
        )}

        <Typography variant="h6" fontWeight="500">
          {t(translatedTextKey)}
        </Typography>
      </Stack>
    </ListItemButton>
  )
}
