import React from "react"

import ProtectedView from "@components/ProtectedView"

import { UnprotectedLayout } from "./UnprotectedLayout"

const Layout: React.FC = () => {
  return (
    <ProtectedView>
      <UnprotectedLayout />
    </ProtectedView>
  )
}

export default Layout
