import React from "react"

import { OrganizationSwitcher } from "@clerk/clerk-react"
import { dark } from "@clerk/themes"
import Box from "@mui/material/Box"

import useSession from "@hooks/useSession"

import ErrorBoundary from "@components/ErrorBoundary"
import { useThemeContext } from "@providers/AppThemeProvider"

import { COMPANY_URL } from "@constants/globalStrings"
import routes from "@constants/routes"

const OrgSwitcher: React.FC = () => {
  const { darkMode } = useThemeContext()
  const { clerkUser } = useSession()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isDigitEmployee = clerkUser?.emailAddresses?.some((emailAddress: any) =>
    emailAddress.emailAddress.includes(COMPANY_URL),
  )

  return (
    <ErrorBoundary>
      {isDigitEmployee && (
        <Box sx={{ px: 2, mt: -1 }}>
          <OrganizationSwitcher
            afterSelectOrganizationUrl={routes.DASHBOARD}
            appearance={{
              baseTheme: darkMode ? dark : undefined,
              elements: {
                "cl-organizationSwitcherTrigger:hover": {
                  backgroundColor: "transparent",
                },
                organizationSwitcherPopoverActions: {
                  "> div": {
                    maxHeight: "50vh",
                  },
                },
                organizationSwitcherPopoverActionButton__createOrganization: {
                  visibility: "hidden",
                  "max-height": 0,
                  "min-height": "unset",
                  padding: "unset",
                },
                "cl-avatarImage": { maxHeight: 10, maxWidth: 10 },
              },
            }}
            hidePersonal
          />
        </Box>
      )}
    </ErrorBoundary>
  )
}

export default OrgSwitcher
