import { useContext } from "react"

import {
  AppDrawerContext,
  AppDrawerContextType,
} from "@providers/AppDrawerProvider"

export const useAppDrawer = (): AppDrawerContextType => {
  const context = useContext(AppDrawerContext)

  if (context === undefined) {
    throw new Error("useAppDrawer must be used within an AppDrawerProvider")
  }

  return context
}
