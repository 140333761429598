import { Feature } from "@gql/graphql"

import { FeatureNavs } from "@constants/features"

import { SubMenu } from "../SubMenu"
import { SubMenuItemWithChildren } from "../SubMenuItemWithChildren"
import { SubMenuItemWithLink } from "../SubMenuItemWithLink"

export const prependFavoriteFeatures = ({
  authorizedSubMenus,
  favoriteFeatures,
  featureNavs,
}: {
  authorizedSubMenus: SubMenu[]
  favoriteFeatures: Pick<Feature, "name">[]
  featureNavs: FeatureNavs
}): SubMenu[] => {
  const favoriteFeatureItems: (
    | SubMenuItemWithLink
    | SubMenuItemWithChildren
  )[] = []

  for (let i = 0; i < favoriteFeatures.length; i++) {
    const navItem = featureNavs[favoriteFeatures[i].name]

    if (navItem) {
      favoriteFeatureItems.push({
        route: navItem.route,
        translatedTextKey: navItem.translatedTextKey,
        icon: navItem.icon,
      })
    }
  }

  if (favoriteFeatureItems.length > 0) {
    return [
      {
        id: "favorites",
        translatedTextKey: "features_starred",
        items: favoriteFeatureItems,
      },

      ...authorizedSubMenus,
    ]
  }

  return authorizedSubMenus
}
