export const colors = {
  BG_DARK_GRAY: "#424243",
  BG_DARK_GRAY_2: "#2a2929",
  BG_GRAY: "#F5F5F7",
  BG_LIGHT_GRAY: "#F3F3F3",
  BG_LIGHT_GRAY_2: "#EFEFEF",
  BG_LIGHT_GRAY_3: "#F6F6F6",
  BG_LIGHT_GRAY_4: "#F9F8F8",
  BORDER_GRAY: "#EDEAE9",
  BUTTON_RED: "#DD4A4A",
  DARK_SECONDARY: "#98B2FB",
  PRIMARY: "#202124",
  SECONDARY: "#6697FA",
  STANDARD_WHITE: "#FFFFFF",
  STATUS_GREEN_BG: "#CDFEE1",
  STATUS_GREEN_TXT: "#167C2E",
  STATUS_RED_BG: "#FF7B7B",
  STATUS_RED_TXT: "#FF3D00",
  TEXT_DARK_GRAY: "#6B7280",
  TEXT_DARK: "#1E1F21",
  TEXT_LIGHT_GRAY: "#AFAFB0",
  YELLOW: "#FFD700",
}

export function getColorForValueSign(
  value: number | string | null | undefined,
): string | null {
  if (value === null || value === undefined) {
    return null
  }

  const numericValue = typeof value === "string" ? parseInt(value, 10) : value
  return numericValue < 0 ? colors.STATUS_RED_TXT : null
}
