import React, { ReactNode } from "react"

import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { I18nextProvider } from "react-i18next"
import { BrowserRouter } from "react-router-dom"

import { AnalyticsProvider } from "@providers/AnalyticsProvider"
import { AppDrawerProvider } from "@providers/AppDrawerProvider"
import AppThemeProvider from "@providers/AppThemeProvider"
import AuthProvider from "@providers/AuthProvider"
import DataProvider from "@providers/DataProvider"
import SessionProvider from "@providers/SessionProvider"
import SnackbarProvider from "@providers/SnackbarProvider"

import i18n from "@services/i18n"

export const AllProviders: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <AuthProvider>
      <DataProvider>
        <SessionProvider>
          <AppThemeProvider>
            <I18nextProvider i18n={i18n}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SnackbarProvider>
                  <AppDrawerProvider>
                    <BrowserRouter>
                      <AnalyticsProvider>{children}</AnalyticsProvider>
                    </BrowserRouter>
                  </AppDrawerProvider>
                </SnackbarProvider>
              </LocalizationProvider>
            </I18nextProvider>
          </AppThemeProvider>
        </SessionProvider>
      </DataProvider>
    </AuthProvider>
  )
}
