import { useCallback, useState } from "react"

export interface UseToggleProps {
  initialValue?: boolean
  onToggle?: (updatedIsActive: boolean) => void
}

export type UseTogglePayload = [isActive: boolean, onToggle: () => void]

export const useToggle = ({
  initialValue,
  onToggle: onToggleProp,
}: UseToggleProps): UseTogglePayload => {
  const [isActive, setIsActive] = useState<boolean>(initialValue ?? false)

  const onToggle = useCallback(() => {
    setIsActive((active) => {
      const newIsActive = !active

      onToggleProp?.(newIsActive)

      return newIsActive
    })
  }, [onToggleProp])

  return [isActive, onToggle]
}
