import React from "react"

import Box from "@mui/material/Box"
import Stack from "@mui/system/Stack"
import { Outlet } from "react-router-dom"

import { useAppDrawer } from "@hooks/useAppDrawer"

import { useThemeContext } from "@providers/AppThemeProvider"

import { colors } from "@constants/colors"

import { AppDrawer, appDrawerWidth } from "../AppDrawer"
import { appBarHeight } from "../Appbar"
import { TopNavigation } from "../TopNavigation"

export const UnprotectedLayout: React.FC = () => {
  const { toggleDrawer, open } = useAppDrawer()
  const { darkMode } = useThemeContext()

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: darkMode
          ? colors.BG_DARK_GRAY_2
          : colors.BG_LIGHT_GRAY_4,
      }}
    >
      <TopNavigation onToggleAppDrawer={toggleDrawer} />

      <Stack direction="row" sx={{ mt: 6, background: "transparent" }}>
        <AppDrawer />

        <Box
          sx={{
            marginTop: `${appBarHeight}px`,
            minHeight: "100vh",
            ml: open ? `${appDrawerWidth}px` : 0,
            px: 3,
            width: open ? `calc(100% - ${appDrawerWidth}px)` : "100%",
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  )
}
