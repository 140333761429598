import { LightVsDarkStylesParams } from "@helpers/lightVsDarkStyles"

export const activeLinkStyles = ({
  isActive,
}: {
  isActive: boolean
}): LightVsDarkStylesParams => ({
  lightStyles: {
    backgroundColor: isActive ? "grey.300" : "transparent",
  },
  darkStyles: {
    backgroundColor: isActive ? "grey.800" : "transparent",
  },
})
