import React, { createContext, useMemo, useCallback } from "react"

import { useToggle } from "@hooks/useToggle"

import ls, { localKeys } from "@services/localStorage"

export interface AppDrawerContextType {
  open: boolean
  toggleDrawer: () => void
}

export const AppDrawerContext = createContext<AppDrawerContextType | undefined>(
  undefined,
)

interface AppDrawerProviderProps {
  children: React.ReactNode
}

export const AppDrawerProvider: React.FC<AppDrawerProviderProps> = ({
  children,
}) => {
  const initialState =
    ls.getItem(localKeys.DRAWER) === null ? true : ls.getItem(localKeys.DRAWER)

  const [open, toggleOpen] = useToggle({ initialValue: Boolean(initialState) })

  const toggleDrawer = useCallback(() => {
    ls.setItem(localKeys.DRAWER, !open)

    toggleOpen()
  }, [open, toggleOpen])

  const memoizedValue = useMemo(() => {
    return { open, toggleDrawer }
  }, [open, toggleDrawer])

  return (
    <AppDrawerContext.Provider value={memoizedValue}>
      {children}
    </AppDrawerContext.Provider>
  )
}
