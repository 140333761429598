import { type Theme } from "@mui/system"

interface ThemeWithModeOnly {
  palette: { mode: Theme["palette"]["mode"] }
}

/**
 * @note if you're within a component that has access to the theme, you can use `const { darkMode } = useThemeContext()
 */
export const isDarkMode = (theme: ThemeWithModeOnly): boolean => {
  return theme.palette.mode === "dark"
}
