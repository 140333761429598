import React from "react"

import ListItemButton from "@mui/material/ListItemButton"
import Typography from "@mui/material/Typography"
import Stack from "@mui/system/Stack"
import { useTranslation } from "react-i18next"
import { useMatch } from "react-router-dom"

import AnchorLink from "@components/AnchorLink"
import { HeroIcon } from "@components/HeroIcon"
import { lightVsDarkStyles } from "@helpers/lightVsDarkStyles"

import { activeLinkStyles } from "../activeLinkStyles"
import { subMenuItemIconColor, subMenuItemIconSize } from "../subMenuItemUtils"
import { SubMenuItemBase } from "../types"

export interface SubMenuItemWithLink extends SubMenuItemBase {
  route: string
}

export const SubMenuItemWithLink: React.FC<SubMenuItemWithLink> = ({
  icon,
  translatedTextKey,
  route,
}) => {
  const { t } = useTranslation()
  const isActive = useMatch(route)

  return (
    <AnchorLink url={route}>
      <ListItemButton
        sx={lightVsDarkStyles({
          defaultStyles: { pl: 1, py: 0.5, mr: 1, borderRadius: 0.5 },
          ...activeLinkStyles({ isActive: Boolean(isActive) }),
        })}
      >
        <Stack direction="row" gap={1} alignItems="center">
          {icon && (
            <div data-testid="submenu-item-icon">
              <HeroIcon color={subMenuItemIconColor} size={subMenuItemIconSize}>
                {icon}
              </HeroIcon>
            </div>
          )}

          <Typography variant="h6" fontWeight="500">
            {t(translatedTextKey)}
          </Typography>
        </Stack>
      </ListItemButton>
    </AnchorLink>
  )
}
