import React from "react"

import Typography from "@mui/material/Typography"
import Stack from "@mui/system/Stack"
import { useTranslation } from "react-i18next"

import { lightVsDarkStyles } from "@helpers/lightVsDarkStyles"

import { SubMenuItemWithChildren } from "../SubMenuItemWithChildren"
import { SubMenuItemWithClickHandler } from "../SubMenuItemWithClickHandler"
import { SubMenuItemWithLink } from "../SubMenuItemWithLink"

export interface SubMenu {
  id: string
  translatedTextKey?: string
  items: (
    | SubMenuItemWithChildren
    | SubMenuItemWithLink
    | SubMenuItemWithClickHandler
  )[]
}

export const SubMenu: React.FC<Omit<SubMenu, "id">> = ({
  translatedTextKey,
  items,
}) => {
  const { t } = useTranslation()

  return (
    <Stack direction="column" sx={{ pl: 2 }} gap={0.5}>
      {translatedTextKey && (
        <Typography
          variant="h6"
          sx={lightVsDarkStyles({
            lightStyles: { color: "grey.600" },
            darkStyles: { color: "grey.400" },
            defaultStyles: { pl: 1 },
          })}
        >
          {t(translatedTextKey)}
        </Typography>
      )}

      {items.map((item) => {
        if ("items" in item) {
          return (
            <SubMenuItemWithChildren
              key={item.translatedTextKey}
              icon={item.icon}
              translatedTextKey={item.translatedTextKey}
              items={item.items}
            />
          )
        }

        if ("route" in item) {
          return (
            <SubMenuItemWithLink
              key={item.translatedTextKey}
              icon={item.icon}
              translatedTextKey={item.translatedTextKey}
              route={item.route}
            />
          )
        }

        return (
          <SubMenuItemWithClickHandler
            key={item.translatedTextKey}
            icon={item.icon}
            translatedTextKey={item.translatedTextKey}
            onClick={item.onClick}
            isActive={item.isActive}
          />
        )
      })}
    </Stack>
  )
}
