import { useState } from "react"

import { SettingsPageName } from "./types"

export const useSettingsPageTabRouter = (): [
  page: SettingsPageName,
  setPage: (page: SettingsPageName) => void,
] => {
  const [page, setPage] = useState<SettingsPageName>("general")

  return [page, setPage]
}
