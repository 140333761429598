import React from "react"

import { SystemStyleObject, Theme } from "@mui/system"
import Stack from "@mui/system/Stack"

import { SubMenu } from "./SubMenu"

export interface SidebarMenuProps {
  subMenus: SubMenu[]
  orgSwitcher?: React.ReactNode
  stackSx?: SystemStyleObject<Theme>
}

export const SidebarMenu: React.FC<SidebarMenuProps> = ({
  subMenus,
  orgSwitcher = null,
  stackSx = {},
}) => {
  return (
    <Stack
      direction="column"
      gap={2}
      sx={{ pt: 6, pb: 8, ...stackSx }}
      data-testid="app-drawer-menu"
    >
      {orgSwitcher}

      {subMenus.map(({ translatedTextKey: titleKey, items, id }) => {
        return <SubMenu translatedTextKey={titleKey} items={items} key={id} />
      })}
    </Stack>
  )
}
