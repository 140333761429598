import React from "react"

import Lazy from "@views/lazy"

import type { SettingsPageName } from "./types"

export const SettingsPage: React.FC<{ currentPageName: SettingsPageName }> = ({
  currentPageName: page,
}) => {
  switch (page) {
    case "general":
      return <Lazy.General />
    case "permissionsBilling":
      return <Lazy.RolesPermissions />
    case "integrations":
      return <Lazy.Integrations />
    case "customFields":
      return <Lazy.CustomFields />
    case "operations":
      return <Lazy.Operations />
    case "equipment":
      return <Lazy.Equipment />
    case "tools":
      return <Lazy.Tools />
    case "warehouseLocations":
      return <Lazy.WarehouseLocations />
    case "customDocs":
      return <Lazy.CustomDocs />
    case "customForms":
      return <Lazy.CustomForms />
    case "customLabels":
      return <Lazy.CustomLabels />
    default:
      return null
  }
}
