import React from "react"

import MuiLink from "@mui/material/Link"
import { NavLink as RouterLink } from "react-router-dom"

import ErrorBoundary from "@components/ErrorBoundary"

interface LinkProps {
  children: string | React.ReactNode
  external?: boolean
  url: string
  [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const ExternalLink = ({ url, children, sx, ...props }: LinkProps) => {
  return (
    <MuiLink
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      data-testid="ExternalLink"
      href={url}
      rel="noreferrer nofollow noopener"
      sx={{
        color: "inherit",
        textDecoration: "none",
        ":hover": {
          textDecoration: "underline",
        },
        ...sx,
      }}
      target={url?.includes("mailto:") ? undefined : "_blank"}
    >
      {children}
    </MuiLink>
  )
}

const InternalLink = ({ url, children, sx, ...props }: LinkProps) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <RouterLink
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      data-testid="InternalLink"
      style={({ isActive }) => ({
        color: "inherit",
        textDecoration: "none",
        ":hover": {
          textDecoration: "underline",
        },
        ...sx,
        ...(typeof props.style === "function" ? props.style({ isActive }) : {}),
      })}
      to={url}
    >
      {children}
    </RouterLink>
  )
}

const AnchorLink: React.FC<LinkProps> = ({
  url,
  children,
  external,
  ...props
}: LinkProps) => {
  if (!url) return null

  if (external) {
    return (
      <ErrorBoundary>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ExternalLink url={url} {...props}>
          {children}
        </ExternalLink>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <InternalLink url={url} {...props}>
        {children}
      </InternalLink>
    </ErrorBoundary>
  )
}

export default AnchorLink
